import { on } from 'delegated-events';
import { createFocusTrap, FocusTrap } from 'focus-trap';
import collapse from '../../../javascripts/utils/collapse';

let focustrap: FocusTrap | null = null;

on('click', '.dropdown__button', async (event) => {
  const { currentTarget: $trigger } = event;
  const { parentElement: $container } = $trigger;

  event.preventDefault();

  if (focustrap) {
    focustrap.deactivate();
  } else {
    const { $target } = await collapse($trigger, true);

    focustrap = createFocusTrap([$target, $container ?? $trigger], {
      clickOutsideDeactivates: true,
      async onPostDeactivate() {
        await collapse($trigger, false);
        focustrap = null;
      },
    });

    focustrap.activate();
  }
});

on('click', '.dropdown__item', () => {
  focustrap?.deactivate({
    returnFocus: false,
  });
});
