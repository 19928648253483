import { createFocusTrap, FocusTrap } from 'focus-trap';
import { on } from 'delegated-events';
import {
  autoPlacement,
  autoUpdate,
  computePosition,
  offset,
} from '@floating-ui/dom';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';

let focusTrap: FocusTrap;
let cleanup: () => void;

on('click', 'button.copyright__button', async (event) => {
  const { currentTarget: $button } = event;
  const $parent = $button.closest<HTMLDivElement>('.copyright');
  const $tooltip = $parent?.querySelector<HTMLDivElement>(
    '.copyright__tooltip',
  );

  if ($parent && $tooltip) {
    event.preventDefault();

    if (!$tooltip.hidden) {
      focusTrap?.deactivate();
    } else {
      $tooltip.hidden = false;

      const calculatePosition = async () => {
        const result = await computePosition($button, $tooltip, {
          middleware: [autoPlacement(), offset(15)],
        });

        $tooltip.dataset.placement = result.placement;
        $tooltip.style.insetInlineStart = `${result.x}px`;
        $tooltip.style.insetBlockStart = `${result.y}px`;
      };

      cleanup = autoUpdate($button, $tooltip, calculatePosition);

      focusTrap = createFocusTrap($parent, {
        onDeactivate() {
          $tooltip.hidden = true;
          cleanup?.();
          $button.classList.remove('copyright__button--active');
          invisibleFocus($button);
        },
        onActivate() {
          calculatePosition();
          $button.classList.add('copyright__button--active');
        },
        initialFocus: '.copyright__tooltip',
        clickOutsideDeactivates: true,
        returnFocusOnDeactivate: false,
        preventScroll: false,
      });

      focusTrap.activate();
    }
  }
});
