import Draggabilly from 'draggabilly';
import abort from '../../../javascripts/utils/abort';

class People {
  $people: HTMLElement;
  $scrollArea: HTMLElement;
  $scrollBar: HTMLElement;
  $thumb: HTMLElement;
  $leftScrollButton: HTMLElement | null = null;
  $rightScrollButton: HTMLElement | null = null;
  thumbLeft = 0;
  enableScrollbarUpdates = true;
  enableLeftScrollButton = false;
  enableRightScrollButton = false;

  constructor($people: HTMLElement) {
    this.$people = $people;

    const $scrollArea =
      this.$people.querySelector<HTMLElement>('.people__people') ?? abort();

    const $scrollBar =
      this.$people.querySelector<HTMLElement>('.people__scroll-bar') ?? abort();

    const $thumb =
      $scrollBar.querySelector<HTMLElement>('.people__scroll-bar-thumb') ??
      abort();

    this.$scrollArea = $scrollArea;
    this.$scrollBar = $scrollBar;
    this.$thumb = $thumb;

    // Init everything
    this.initScrollButtons();
    this.initScrollBar();
    this.update();
  }

  initScrollBar() {
    // Update on scroll and initial load
    this.$scrollArea.addEventListener('scroll', () => this.update(), {
      passive: true,
    });

    // Update on resizing of scroll bar
    const resizeObserver = new ResizeObserver(() => this.update());
    resizeObserver.observe(this.$scrollBar);

    // Move on backdrop click
    this.$scrollBar
      .querySelector('.people__scroll-bar-backdrop')
      ?.addEventListener('click', (event) => {
        // @ts-expect-error layerX is a non-standard, but well supported attribute on MouseEvent
        const { layerX } = event;

        this.$scrollArea.scrollLeft =
          (layerX / this.$scrollBar.offsetWidth) * this.$scrollArea.scrollWidth;
      });

    const draggie = new Draggabilly(this.$thumb, {
      axis: 'x',
      containment: true,
    });

    draggie.on('dragStart', () => {
      this.enableScrollbarUpdates = false;
      this.$scrollArea.classList.add('people__people--was-dragged');
    });

    draggie.on('dragMove', (event, pointer, moveVector) => {
      this.$scrollArea.scrollLeft =
        ((this.thumbLeft + moveVector.x) / this.$scrollBar.offsetWidth) *
        this.$scrollArea.scrollWidth;
    });

    draggie.on('dragEnd', () => {
      this.thumbLeft = this.$thumb.offsetLeft;
      this.enableScrollbarUpdates = true;
      this.$scrollArea.classList.remove('people__people--was-dragged');
    });
  }

  initScrollButtons() {
    // Left scroll button
    this.$leftScrollButton = this.$people.querySelector(
      '.people__button--left',
    );

    this.$leftScrollButton?.addEventListener('click', (event) => {
      event.preventDefault();
      this.move('left');
    });

    // Right scroll button
    this.$rightScrollButton = this.$people.querySelector(
      '.people__button--right',
    );

    this.$rightScrollButton?.addEventListener('click', (event) => {
      event.preventDefault();
      this.move('right');
    });
  }

  move(direciton: 'left' | 'right') {
    const width =
      this.$scrollArea.querySelector<HTMLElement>(
        '.people__person',
      )?.offsetWidth;

    if (width) {
      this.$scrollArea.scrollBy({
        left: direciton === 'left' ? width * -1 : width,
      });
    }
  }

  update() {
    if (!this.enableScrollbarUpdates) {
      return;
    }

    window.requestAnimationFrame(() => {
      const width =
        (this.$scrollArea.offsetWidth / this.$scrollArea.scrollWidth) * 100;
      this.thumbLeft =
        (this.$scrollArea.scrollLeft / this.$scrollArea.scrollWidth) *
        this.$scrollBar.offsetWidth;

      this.$thumb.style.setProperty('width', `${width}%`);
      this.$thumb.style.setProperty('left', `${this.thumbLeft}px`);

      this.$scrollBar.classList.toggle(
        'people__scroll-bar--enabled',
        width < 100,
      );

      this.$leftScrollButton?.toggleAttribute(
        'disabled',
        this.$scrollArea.scrollLeft === 0,
      );

      this.$rightScrollButton?.toggleAttribute(
        'disabled',
        this.$scrollArea.scrollWidth <=
          this.$scrollArea.scrollLeft + this.$scrollArea.offsetWidth,
      );
    });
  }
}

document
  .querySelectorAll<HTMLElement>('.people')
  .forEach(($people) => new People($people));

export default People;
