import {
  autoPlacement,
  autoUpdate,
  computePosition,
  flip,
  offset,
} from '@floating-ui/dom';

const alertTooltip = (
  message: string,
  $reference: HTMLElement,
  $anchor = document.body,
  timeout = 2,
) => {
  // Create alert
  const $alert = document.createElement('div');
  $alert.role = 'alert';
  $alert.className = 'u-tooltip';
  $alert.innerText = message;

  // Calculate position
  const calculatePosition = async () => {
    const result = await computePosition($reference, $alert, {
      middleware: [autoPlacement(), offset(15), flip()],
    });

    $alert.dataset.placement = result.placement;
    $alert.style.insetInlineStart = `${result.x}px`;
    $alert.style.insetBlockStart = `${result.y}px`;
  };

  // Insert into DOM
  $anchor.appendChild($alert);
  calculatePosition();

  // Clean up
  const cleanup = autoUpdate($reference, $alert, calculatePosition);

  $alert.addEventListener('transitionend', () => {
    cleanup();
    $alert.remove();
  });

  setTimeout(() => {
    $alert.style.opacity = '0';
  }, timeout * 1000);
};

export default alertTooltip;
