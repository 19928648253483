import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { on } from 'delegated-events';
import { createFocusTrap, FocusTrap } from 'focus-trap';
import abort from '../../../javascripts/utils/abort';
import collapse from '../../../javascripts/utils/collapse';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';
import moveFocus from '../../../javascripts/utils/moveFocus';

let focusMenuTrap: FocusTrap | null = null;
let searchMenuTrap: FocusTrap | null = null;

on('click', '.header__menu [aria-controls]', async (event) => {
  const { currentTarget: $trigger } = event;

  event.preventDefault();

  const { isOpen, $target: $menu } = await collapse($trigger);
  const $header = $trigger.closest('.header') ?? abort();
  const $logo =
    $header.querySelector<HTMLElement>('.header__logo-container') ?? abort();
  const $menuContent =
    $menu.querySelector<HTMLElement>('.menu__content') ?? abort();
  const largeLogo = $header.classList.contains('header--large-logo');

  searchMenuTrap?.deactivate();
  searchMenuTrap = null;

  if (isOpen) {
    $menu.hidden = false;
    focusMenuTrap = createFocusTrap([$menu, $logo], {
      initialFocus: false,
      clickOutsideDeactivates: false,
      returnFocusOnDeactivate: true,
      onActivate() {
        moveFocus($menuContent);
        $header.classList.remove('header--large-logo');
      },
      onPostActivate() {
        disableBodyScroll($menuContent);
      },
      onPostDeactivate() {
        invisibleFocus($trigger);
        enableBodyScroll($menuContent);
      },
      onDeactivate: () => {
        collapse($trigger, false);
        $menu.hidden = true;
        $header.classList.toggle('header--large-logo', largeLogo);
      },
    });

    focusMenuTrap.activate();
  } else {
    focusMenuTrap?.deactivate();
    focusMenuTrap = null;
  }
});

on('click', '.header__menu [data-close-menu]', () => {
  focusMenuTrap?.deactivate();
  focusMenuTrap = null;
});

on('click', '.header__search [aria-controls]', async (event) => {
  const { currentTarget: $trigger } = event;

  event.preventDefault();

  const { isOpen, $target: $search } = await collapse($trigger);

  focusMenuTrap?.deactivate();
  focusMenuTrap = null;

  if (isOpen) {
    $search.hidden = false;
    searchMenuTrap = createFocusTrap([$search, $trigger], {
      initialFocus: 'input',
      clickOutsideDeactivates: true,
      allowOutsideClick: true,
      returnFocusOnDeactivate: true,
      onPostActivate() {
        disableBodyScroll($search);
      },
      onPostDeactivate() {
        invisibleFocus($trigger);
        enableBodyScroll($search);
      },
      onDeactivate: () => {
        collapse($trigger, false);
        $search.hidden = true;
      },
    });

    searchMenuTrap.activate();
  } else {
    searchMenuTrap?.deactivate();
    searchMenuTrap = null;
  }
});

on('click', '.header__search [data-close-search]', () => {
  searchMenuTrap?.deactivate();
  searchMenuTrap = null;
});
