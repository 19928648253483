import { on } from 'delegated-events';

on('click', '.podcast-episode__preview', (event) => {
  event.preventDefault();

  // Get container
  const { currentTarget: $preview } = event;
  const $podcastEpisode = $preview.closest<HTMLElement>('.podcast-episode');
  const $code = $podcastEpisode?.querySelector<HTMLTemplateElement>(
    '.podcast-episode__code',
  );

  if (!$code) {
    return;
  }

  // Delete preview
  $preview.remove();

  // Unpack template with content
  $podcastEpisode?.appendChild($code.content.cloneNode(true));

  // Wait for player
  const playerAfterReady = (messageEvent: MessageEvent) => {
    const data = JSON.parse(messageEvent.data);

    if (data.event === 'ready') {
      const $iframe =
        $podcastEpisode?.querySelector<HTMLIFrameElement>('iframe');

      $iframe?.contentWindow?.postMessage(
        JSON.stringify({
          context: data.context,
          version: data.version,
          method: 'play',
        }),
      );

      window.removeEventListener('message', playerAfterReady);
    }
  };

  window.addEventListener('message', playerAfterReady);
});
