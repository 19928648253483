import { on } from 'delegated-events';
import alertTooltip from '../utils/alertTooltip';

on('click', '[data-copy-to-clipboard]', async (event) => {
  const { currentTarget: $trigger } = event;

  const { copyToClipboard: target, copyToClipboardSucess: successMessage } =
    $trigger.dataset;

  if (target) {
    const $target = document.getElementById(target);

    if ($target) {
      let copyValue: string;

      if (
        $target instanceof HTMLInputElement ||
        $target instanceof HTMLTextAreaElement
      ) {
        $target.select();
        $target.setSelectionRange(0, $target.value.length);
        copyValue = $target.value;
      } else {
        copyValue = $target.innerText;
      }

      await navigator.clipboard.writeText(copyValue);

      if (successMessage && $trigger.parentElement) {
        alertTooltip(successMessage, $trigger, $trigger.parentElement);
      }
    }
  }
});
